import type { Shop, User } from "@/types";

interface Props {
  shop: Shop;
  user?: User | null;
}

const Hero = ({ shop, user }: Props) => {
  return (
    <section className="relative w-full h-[calc(100vh-80px)] min-h-[400px] max-h-[980px] overflow-hidden">
      <div className="absolute inset-0">
        <img
          src="/img/home/kundarilla-hero-image.webp"
          alt="Kundarilla Palace"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-kp-black/70 to-kp-black/30" />
      </div>

      <div className="relative container mx-auto px-4 h-full flex items-center">
        <div className="max-w-2xl text-kp-white">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 md:mb-6 drop-shadow-lg">
            Discover <span className="text-kp-primary">Premium</span> Suitcase
            Sets for Every Journey
          </h1>
          <p className="text-lg md:text-xl mb-6 md:mb-8 drop-shadow-lg">
            At Kundarilla Palace, we specialize in curating the finest
            collection of suitcase sets. From compact carry-ons to spacious
            travel companions, find your perfect match for every adventure.
          </p>
          <a href="#collections">
            <button className="px-6 py-3 md:px-8 md:py-3 bg-kp-accent text-kp-black rounded-full text-base md:text-lg font-semibold transition-colors hover:bg-kp-black hover:text-kp-white">
              Explore Sets
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
